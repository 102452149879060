<template>
  <v-app>
    <RouterView />
    <Footer />
		<ToTop />
  </v-app>
</template>

<script>
import Footer from '@/components/UI/Footer.vue'
import ToTop from '@/components/UI/ToTop.vue'
export default {
  name: "App",
  components: {
    Footer,
		ToTop
  },

  data: () => ({
    //
  }),
};
</script>
