<template>
	<div>
		<span @click="toTop()" class="toTop">
		</span>
	</div>
</template>

<script>
export default{
	mounted()
	{
		const toTop = document.querySelector(".toTop");
		window.addEventListener("scroll", () => {
			if(window.pageYOffset > window.innerHeight / 2)
			{
				toTop.classList.add("active");
			}
			else
			{
				toTop.classList.remove("active");
			}
		})

	},
	methods: {
		toTop() {
			window.scrollTo({top:0,left:0, behavior: 'smooth'})
		}
	}
}
</script>
<style scoped lang="scss">
.toTop{
	position: fixed;
	bottom: 16px;
	right: 32px;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: #F2F2F2;
	border: 2px solid rgba(243, 147, 0, 0.4);
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(5px);
	cursor: pointer;
	text-decoration: none;
	opacity: 0; 
	z-index: 1000;
	transition: all 0.3s ease-out;
}
.toTop::before{
	content: '';
	position: absolute;
	height: 15px;
	width: 15px;
	border-top: 3px solid #F39300;
	border-left: 3px solid #F39300;
	transform: rotate(45deg);
	left: 14px;
	top: 19.2px;
	transition: all 0.3s ease-out;
}
.toTop:hover{
	background-color: #F39300;
	&::before{
		border-color: #FFF;
	}
}
.toTop.active
{
	bottom: 32px;
	opacity: 1;
}
</style>