import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "main",
        component: () => import("@/views/MainPage.vue")
    },
    {
        path: "/all",
        name: "all",
        component: () => import("@/views/AllProjects.vue")
    },
    {
        path: "/project/:id",
        name: "project",
        component: () => import("@/views/Project.vue")
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;