<template>
	<div style="display: flex; flex-direction: column; align-items: center;">
			<div class="footer">
				<v-container>
					<v-row class="justify-space-between">
				<div class="footer__desc">
				<a href="/"><img src="@/assets/images/footer-logo.png" alt="logo"></a> 
					<div style="margin-top: 20px;">Unser Team übernimmt alles, von der Idee über die Konzeptentwicklung bis zur 
						Realisierung. Wir glauben an Traditionen und lasse
						n sie in unsere Innovationen einfließen, denn der Kunde ist die Seele des Projekts.</div>
				</div>
				<div class="footer__contacts">
					<div class="footer__contacts-item">
						<div class="footer__contacts-item-title">
							<div class="footer-line"></div>
							<div class="footer-title-text">aufrufen</div>
						</div>
						<a href="tel:41798747508" target="_blank" class="footer__contacts-item-link">+41 76 404 01 19</a>
					</div>

					<div class="footer__contacts-item">
						<div class="footer__contacts-item-title">
							<div class="footer-line"></div>
							<div class="footer-title-text">schreiben</div>
							
						</div>
						<a href="mailto:info@rietli-immobilien.ch" target="_blank" class="footer__contacts-item-link">1info@rietli-immobilien.ch</a>
					</div>
						
					<div class="footer__contacts-item">
						<div class="footer__contacts-item-title">
							<div class="footer-line"></div>
							<div class="footer-title-text">Adresse</div>
							
						</div>
						<a href="https://maps.google.com/?q=hauptstrasse 41, ch-9477 trübbach" target="_blank" class="footer__contacts-item-link">hauptstrasse 41, ch-9477 trübbach</a>
					</div>
				</div>
					</v-row>
				</v-container>
			</div>
			
			<div class="footer-developed">
				<div class="footer-company">© Rietli Immobilien AG 2023</div>
				<span class="developed-line"> </span>
				<div class="footer-bmax">
					Developed by <a href="https://bmax.com.ua" target="_blank"><img style="width: 80px;height: 40px; margin-left: 8px;" src="@/assets/images/BMaxLogo.png" alt="bMax logo"></a>
				</div>
				</div>
	</div>
</template>

<script>
export default{
	mounted(){
		var items = document.querySelectorAll('.footer__contacts-item');

for(let i = 0;i<items.length;i++)
{
	items[i].addEventListener('mouseover', function() {
	var line = items[i].querySelector('.footer-line');
  line.classList.add('rotate-line');
});
}
for(let i = 0;i<items.length;i++)
{
	items[i].addEventListener('mouseout', function() {
	var line = items[i].querySelector('.footer-line');
  line.classList.remove('rotate-line');
});
}
	}
}

</script>

<style scoped>
a{
	text-decoration: none;
	color: inherit;
}
.footer{
	background-color: #121212;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 100px 64px;
}
.footer__desc{
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #EFEFEF;
	max-width: 414px;
	width: 100%;
}
.footer__contacts{
	display: flex;
	justify-content: center;
	align-items: flex-start;
}
.footer__contacts-item{
	max-width: 190px;
	width: 100%;
	font-style: normal;
}
.footer__contacts-item:nth-child(2){
	margin-left: 34px;
	margin-right: 32px;
}
.footer__contacts-item-title
{
	display: flex;
}
.footer-title-text{
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
	color: #FFFFFF;
	margin-bottom: 20px;
	padding-left: 20px;
}
.footer-line{
	width: 36px;
	height: 1px;
	background: #FFF;
	position: relative;
	top: 10px;
	transition: transform 0.3s ease-in-out;
}
.rotate-line {
  transform: rotate(-25deg);
}

.footer__contacts-item-link{
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
	color: #FFFFFF;
}
.footer-developed{
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #EEEEEE;
	background-color: #121212;
	width: 100%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.developed-line{
	width: 15px;
	transform: rotate(90deg);
	border: 1px solid #2C3535;
	margin: 0 5px;
}
.footer-bmax{
	display: flex;
	align-items: center;
}
@media(max-width: 1276px) {
	.footer__desc{
		margin-bottom: 32px;
	}
}
@media(max-width: 730px)
{
	.footer{
		padding: 40px 16px;
	}
}
@media(max-width: 630px){
	.footer__contacts{
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.footer__contacts-item{
		margin-bottom: 40px;
	}
}
@media(max-width: 500px){
	.footer__contacts-item:nth-child(2){
		margin-left: 0px;
		margin-right: 0px;
	}
}
@media(max-width: 420px){
	.developed-line{
		width: 18px;
		transform: rotate(0deg);
		margin: 15px 0;
	}
	.footer-developed{
		flex-direction: column;
		padding-top: 24px;
	}
	.footer__contacts-item{
		max-width: 100%;
		
	}
}
</style>